import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, computed } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, map, of } from 'rxjs';

import { Status } from '../../../enums/status.enum';
import { customException } from '../../../utils/capture-exception.util';
import { AddFavorite, BuildingCard, RemoveFavorite, ReplaceFavorites } from './favorites.interface';
import { FavouritesState } from './store/favorites.reducer';
import { addFavorite, getFavorites, removeFavorite, removeManyFavorites, replaceFavorites } from './store/favourites.actions';
import {
  selectFavoriteByBuildingId,
  selectFavouriteEntities,
  selectFavourites,
  selectFavouritesLoadingStatus,
} from './store/favourites.selectors';

/**
 * Сервис для управления избранными пользователями.
 * @class СлужбаИзбранного
 * @constructor
 */
@Injectable({ providedIn: 'root' })
export class FavoritesService {
  /**
   * Извлекает избранное из функции selectSignal магазина.
   *
   * @returns {Observable} - Observable, эмитирующий текущее избранное.
   */
  favourites = this.store$.selectSignal(selectFavourites);

  /**
   * Извлекает имена папок
   *
   * @returns {Signal<string[]>} - Массив с именами папок.
   */
  folderNames = computed(() =>
    this.favourites()
      .filter((favorite) => !favorite.address)
      .map((folder) => folder.name),
  );

  /**
   * Выбирает из хранилища избранные сущности с помощью сигнала и сохраняет их в переменную favouriteEntities.
   *
   * @type {Observable<any>}
   */
  favouriteEntities = this.store$.selectSignal(selectFavouriteEntities);

  /**
   * Представляет статус загрузки избранного.
   * @type {Observable<boolean>}
   */
  loadingStatus = this.store$.selectSignal(selectFavouritesLoadingStatus);

  /**
   * Извлекает избранное по ID здания из хранилища.
   *
   * @function getFavoriteByBuildingId
   * @returns {Observable<Favorite>} Обсервабл, который эмитирует избранное с указанным ID здания.
   */
  readonly getFavoriteByBuildingId = this.store$.selectSignal(selectFavoriteByBuildingId);

  /**
   * Переменная `apiUri` содержит строку, представляющую конечную точку API.
   * Этот URI указывает на маршрут для запроса связанных с избранными данными.
   */
  readonly apiUri = '/meta_api/favorites';

  /**
   * Представляет id пользователя, полученный из hostname текущего документа.
   *
   * @type {string}
   */
  #userId = this.document.location.hostname;

  /**
   * Конструктор класса.
   *
   * @param {Document} document Объект документа, внедрённый через DI.
   * @param {HttpClient} httpClient Сервис HTTP-клиента для выполнения запросов.
   * @param {Store<FavouritesState>} store$ Хранилище состояния избранного.
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpClient: HttpClient,
    private store$: Store<FavouritesState>,
  ) {}

  /**
   * Выполняет действие по извлечению избранного.
   *
   * @returns {void} Указывает, что метод не возвращает значение.
   */
  getFavoritesAction(): void {
    this.loadingStatus() === Status.UNINITIALIZED && this.store$.dispatch(getFavorites());
  }

  /**
   * Извлекает избранные карточки зданий для пользователя.
   *
   * @returns {Observable<BuildingCard[]>} - Обсервабл, который эмитирует массив объектов BuildingCard, представляющих избранные карточки зданий.
   */
  getFavorites(): Observable<BuildingCard[]> {
    const url = `${this.apiUri}/?user_id=${this.#userId}`;
    return this.httpClient.get<BuildingCard[]>(url).pipe(
      map((buildingCards) => {
        return buildingCards.map((buildingCard) => (buildingCard.guid ? { ...buildingCard, buildingId: buildingCard.guid } : buildingCard));
      }),
      catchError((err) => {
        customException({ msg: 'getFavorites', err });

        return of([]);
      }),
    );
  }

  /**
   * Добавляет действие в избранное в хранилище.
   *
   * @param {BuildingCard} favorite - Карточка здания, добавляемая в избранное.
   * @return {void}
   */
  addFavoriteAction(favorite: BuildingCard): void {
    this.store$.dispatch(addFavorite({ favorite }));
  }

  /**
   * Добавляет карточку здания в избранное для пользователя.
   *
   * @param {BuildingCard} buildingCard - Карточка здания, добавляемая в избранное.
   * @return {Observable<AddFavorite>} - Обсервабл, который эмитирует результат добавления карточки здания в избранное.
   */
  addFavorite(buildingCard: BuildingCard): Observable<AddFavorite | BuildingCard> {
    const url = `${this.apiUri}/add/`;
    const payload = { guid: buildingCard.buildingId, user_id: this.#userId };

    return this.httpClient.post<AddFavorite>(url, payload);
  }

  /**
   * Обновляет карточку здания в избранном новым именем, если найдено совпадение.
   *
   * @param {BuildingCard} buildingCard - Карточка здания, которую нужно обновить в списке избранного.
   * @return {void} - Этот метод ничего не возвращает.
   */
  updateFavorite(buildingCard: BuildingCard): void {
    const favorites = this.favourites();

    const updatedFavorites = favorites.map((favorite) => {
      if (favorite.buildingId === buildingCard.buildingId) {
        return { ...favorite, name: buildingCard.name };
      } else {
        return favorite;
      }
    });

    this.replaceFavoritesAction(favorites, updatedFavorites);
  }

  /**
   * Удаляет действие из избранного из хранилища.
   *
   * @param {BuildingCard} favorite - Избранное, которое необходимо удалить.
   *
   * @return {void}
   */
  removeFavoriteAction(favorite: BuildingCard): void {
    this.store$.dispatch(removeFavorite({ favorite }));
  }

  /**
   * Удаляет несколько элементов из списка избранного.
   *
   * @param {string[]} favoriteIds - Массив идентификаторов, соответствующих элементам, которые необходимо удалить из избранного.
   *
   * @return {void} Этот метод не возвращает значение.
   */
  removeFavoriteManyAction(favoriteIds: string[]): void {
    this.store$.dispatch(removeManyFavorites({ favoriteIds }));
  }

  /**
   * Удаляет избранное здание.
   *
   * @param {number} buildingId - ID здания, которое нужно удалить из избранного.
   * @return {Observable<RemoveFavorite>} - Обсервабл типа RemoveFavorite.
   */
  removeFavorite(buildingId: BuildingCard['buildingId']): Observable<RemoveFavorite> {
    const url = `${this.apiUri}/delete/?guid=${buildingId}&user_id=${this.#userId}`;
    return this.httpClient.delete<RemoveFavorite>(url);
  }

  /**
   * Заменяет избранное в хранилище на новое избранное.
   *
   * @param {BuildingCard[]} prevFavorites - Массив предыдущего избранного.
   * @param {BuildingCard[]} newFavorites - Массив нового избранного.
   * @return {void} - Этот метод ничего не возвращает.
   */
  replaceFavoritesAction(prevFavorites: BuildingCard[], newFavorites: BuildingCard[]): void {
    this.store$.dispatch(replaceFavorites({ prevFavorites, newFavorites }));
  }

  /**
   * Заменяет избранные карточки зданий пользователя на предоставленный список избранного.
   *
   * @param {BuildingCard[]} favorites - Новые избранные карточки зданий для замены существующих избранных у пользователя.
   * @return {Observable<ReplaceFavorites>} - Обсервабл, который эмитирует результат замены избранного.
   */
  replaceFavorites(favorites: BuildingCard[]): Observable<ReplaceFavorites> {
    const url = `${this.apiUri}/reorder/`;
    const requestBody = { user_id: this.#userId, reorder_guids: favorites.map((val) => val.buildingId) };

    return this.httpClient.post<ReplaceFavorites>(url, requestBody);
  }

  /**
   * Проверяет, существует ли указанное имя папки в списке имен папок.
   *
   * @param {string} folderName - Имя папки для проверки.
   * @return {boolean} - Возвращает true, если имя папки существует, иначе false.
   */
  isValueExists(folderName: string): boolean {
    return this.folderNames()
      .map((folderName) => folderName.toLowerCase())
      .includes(folderName.toLowerCase());
  }
}
